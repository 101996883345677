<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs"
                            tag="div"
                            class="px-4">
            <TextField v-model="name"
                       name="name"
                       ref="name"
                       rules="required"
                       :label="$t('labels.holiday_name')"></TextField>
            <DatePicker v-model="date"
                        name="date"
                        ref="date"
                        rules="required"
                        :label="$t('labels.date')"></DatePicker>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/widgets/forms/TextField';
import DatePicker from '@/components/widgets/forms/DatePicker';

export default {
    name: 'ManageHolidayForm',
    components: { ValidationObserver, TextField, DatePicker },
    computed: {
        ...mapFields('holidaysCalendar', [
            'holidayData.name',
            'holidayData.date'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>

<style scoped>

</style>
