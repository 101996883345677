<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout justify-end
                  class="mb-8">
            <v-btn color="secondary"
                   v-if="canAddHoliday"
                   @click="openForm(null)"
                   data-test="addNewGroupBtn">{{ $t('buttons.add_new_holiday') }}</v-btn>
        </v-layout>
        <v-data-table :headers="headers"
                      :loading="loader"
                      disable-sort
                      :items="holidays"
                      disable-pagination
                      hide-default-footer
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>

            <template v-slot:item.actions="{ item }">
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.EDIT"
                             v-if="canEditHoliday"
                             @click="openForm(item)"
                             :data-test="item"
                             :tooltip="$t('actions.edit')"></HintingIcon>

                <ButtonWithConfirmation :item-id="item.id"
                                        v-if="canRemoveHoliday"
                                        @action-executed="emitFetch"
                                        :callback="deleteHoliday"></ButtonWithConfirmation>
            </template>
        </v-data-table>

        <Pagination :pagination="pagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import Pagination from '@/components/widgets/tables/Pagination';
import HintingIcon from '@/components/widgets/HintingIcon';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'HolidaysCalendarTable',
    components: { SortableHeader, Pagination, HintingIcon, ButtonWithConfirmation },
    mixins: [paginationSettings],
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        }
    },
    data: (vm) => ({
        ICONS,
        pagination: vm.setupPagination(),
        sortable: {
            name: 'asc',
            date: null
        }
    }),
    computed: {
        headers () {
            return [
                {
                    text: this.$t('labels.date'),
                    value: 'date',
                    width: '250px',
                    sortable: true
                },
                {
                    text: this.$t('labels.holiday_name'),
                    value: 'name',
                    sortable: true
                },
                {
                    text: this.$t('incidents.table.headers.actions'),
                    value: 'actions',
                    icon: false,
                    width: '100px',
                    sortable: false
                }
            ];
        },
        ...mapState('holidaysCalendar', [
            'holidays',
            'totalHolidaysAmount'
        ]),
        canAddHoliday () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.SYSTEM_SETTINGS);
        },
        canEditHoliday () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.SYSTEM_SETTINGS);
        },
        canRemoveHoliday () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.SYSTEM_SETTINGS);
        }
    },
    watch: {
        totalHolidaysAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        }
    },
    methods: {
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: event,
                value: this.sortable[event]
            });
        },
        emitFetch () {
            this.$emit('fetch');
        },
        openForm (item = null) {
            if (item) {
                this.$store.commit('holidaysCalendar/SET_HOLIDAY_DATA', item);
                this.$store.commit('holidaysCalendar/SET_EDITED_HOLIDAY_ID', item.id);
                this.$store.commit('holidaysCalendar/SET_HOLIDAY_FORM_VISIBILITY', true);
            } else {
                this.$store.commit('holidaysCalendar/SET_HOLIDAY_FORM_VISIBILITY', true);
            }
        },
        deleteHoliday (id) {
            return this.$store.dispatch('holidaysCalendar/deleteHoliday', id);
        }
    }
};
</script>

<style scoped>

</style>
