<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.holiday_calendar') }}</h1>

        <HolidaysCalendarTable @change-page="changePage($event)"
                               @change-amount="changeAmount($event)"
                               @sort="sortingColumn = $event"
                               @fetch="fetchHolidays"
                               :loader="loader"
                               :changed-page="pagination.page"></HolidaysCalendarTable>

        <ManageHoliday @fetch="fetchHolidays"></ManageHoliday>
    </div>
</template>

<script>
import HolidaysCalendarTable from '@/components/holidaysCalendar/HolidaysCalendarTable';
import ManageHoliday from '@/components/holidaysCalendar/manageHoliday/ManageHoliday';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'HolidaysCalendar',
    components: { HolidaysCalendarTable, ManageHoliday },
    mixins: [paginationSettings],
    data: (vm) => ({
        pagination: vm.setupPagination(),
        loader: false,
        sortingColumn: null
    }),
    watch: {
        sortingColumn: {
            handler () {
                this.fetchHolidays();
            },
            deep: true
        }
    },
    methods: {
        fetchHolidays () {
            this.loader = true;

            const params = {
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            this.$store.dispatch('holidaysCalendar/getHolidays', params)
                .finally(() => {
                    this.loader = false;
                });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchHolidays();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchHolidays();
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.SYSTEM_SETTINGS)) {
                vm.fetchHolidays();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    }
};
</script>

<style scoped>

</style>
